import styled from "styled-components";

import colors from "~/scss/variables";

export const Wrapper = styled.header`
  background-color: white;
  box-shadow: 0 0.2rem 0.2rem ${colors.cGreen1};
  padding: 1.4rem;
  position: fixed;
  width: 100%;
  z-index: 10;

  @media (max-width: 425px) {
    position: initial;
  }

  .gloov {
    height: 7rem;
    margin-left: 2rem;
  }
`;

export const HeaderContact = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;

  .contact {
    align-items: center;
    color: ${colors.cGray3};
    display: flex;
    font-size: 2.2rem;
    font-weight: 500;

    &:not(:last-child) {
      margin-right: 6rem;
    }

    strong {
      font-weight: 900;
    }

    svg {
      color: ${colors.cGreen2};
      font-size: 2rem;
      margin-right: 1rem;
    }
  }

  @media (max-width: 425px) {
    flex-direction: column;

    .contact {
      &:not(:last-child) {
        margin: 0 0 1rem 0;
      }
    }
  }
`;
