import styled from 'styled-components';

import colors from '~/scss/variables';

export const Container = styled.footer`
  background-color: ${colors.cGreen1};
  display: flex;
  justify-content: center;
  padding: .8rem 0 0 0;
`;

export const RaesLink = styled.a``;
