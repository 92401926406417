import React from "react";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { FaPhone, FaWhatsapp } from "react-icons/fa";

import HeaderLogo from "~/images/header-logo.svg";

import { Wrapper, HeaderContact } from "./styles";

export default function Header() {
  return (
    <Wrapper>
      <Container>
        <Columns>
          <Columns.Column size={6} className="has-text-centered">
            <HeaderLogo />
            <img src="gloov.png" alt="Gloov" className="gloov" />
          </Columns.Column>
          <Columns.Column size={6} className="margin-l-auto">
            <HeaderContact>
              <a href="tel:+551126033000" className="contact">
                <FaPhone />
                <p>
                  11 <strong>2603-3000</strong>
                </p>
              </a>

              <a
                href="https://wa.me/5511947441001?text=Olá, gostaria de saber mais sobre a scooter elétrica."
                target="_blank"
                rel="noreferrer noopener"
                className="contact"
              >
                <FaWhatsapp />
                <p>
                  11 <strong>94744-1001</strong>
                </p>
              </a>
            </HeaderContact>
          </Columns.Column>
        </Columns>
      </Container>
    </Wrapper>
  );
}
