export default {
  cGray1: '#eaeaea',
  cGray2: '#b5b5b5',
  cGray3: '#656565',
  cBlue1: '#009eff',
  cGreen1: '#009741',
  cGreen2: '#78ce8d',
  cYellow1: '#ffb600',
  cPink1: '#f7706c',
  cPink2: '#ef69a8',

  fRaleway: '\'Raleway\', sans-serif',
};
