import React from 'react';

import LogoRaes from '~/images/logo-raes.svg';

import { Container, RaesLink } from './styles';


export default function Footer() {
  return (
    <Container>
      <RaesLink>
        <LogoRaes />
      </RaesLink>
    </Container>
  );
}
